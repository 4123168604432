import React, { useContext, useState } from "react";
import { navigate, PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import {
  LayoutMissionDesktop,
  LayoutMissionMobile,
  SEO,
} from "@components/Structural";
import { clickIcon } from "@images";
import TableMB1 from "@components/Missions/SavingAndInvestment/M1B/table/table";
import DescriptionTable from "@components/Missions/SavingAndInvestment/M1B/descriptionTable/descriptionTable";
import { completeMissionMutation, getUserMissions } from "@apollo";
import { MissionsInfo } from "@data";
import { useApolloClient } from "@apollo/client";
import { AppContext } from "@context";
import { nextMissionModalInfo } from "@utils";
import { Route } from "@interfaces";
import { useAuth } from "@hooks";

const savingInvestmentMB1: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery("(max-width: 1200px)");
  const classes = useStyles();
  const [buttonSelect, setButtonSelect] = useState(0);
  const { userMissions } = getUserMissions();
  const client = useApolloClient();
  const [confettiFire, setConfettiFire] = useState<boolean>(false);
  const { openCongratsModal } = useContext(AppContext);

  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.SavingsInvestmentCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.SavingsInvestmentCategory.m1_2.id
  )[0];

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      completeMissionMutation(client, currentMission.mission.id);
      setConfettiFire(true);
      openCongratsModal(
        nextMissionModalInfo(currentMission, categoryMissions)
      );
    } catch (error) {
      // console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };
  return (
    <>
      <SEO />
      {!isMobile ? (
        <LayoutMissionDesktop
          title={"¿Qué instrumentos financieros existen?"}
          number={1}
          subtitle="subtitle"
          confettiFire={confettiFire}
          secondLayoutForShortcuts
          onHouseClick={() => navigate(Route.savingsAndInvestment)}
        >
          <div className={classes.referenceClick}>
            <div>
              <img src={clickIcon} /> Haz click sobre cualquier sección para ver
              lo que és
            </div>
          </div>
          <div className={classes.containerTable}>
            <TableMB1
              setButtonSelect={setButtonSelect}
              buttonSelect={buttonSelect}
              handleClick={handleClick}
            />
            {buttonSelect !== 0 && (
              <div>
                <DescriptionTable buttonSelect={buttonSelect} />{" "}
              </div>
            )}
          </div>
        </LayoutMissionDesktop>
      ) : (
        <LayoutMissionMobile
          title={"¿Qué instrumentos financieros existen?"}
          number={1}
          confettiFire={confettiFire}
          onHouseClick={() => navigate(Route.savingsAndInvestment)}
        >
          <div className={classes.referenceClick}>
            <div>
              <img src={clickIcon} /> Haz click sobre cualquier sección para ver
              lo que és
            </div>
          </div>
          <div className={classes.containerTable}>
            <TableMB1
              setButtonSelect={setButtonSelect}
              buttonSelect={buttonSelect}
              handleClick={handleClick}
            />
          </div>
        </LayoutMissionMobile>
      )}
    </>
  );
};

export default savingInvestmentMB1;

const useStyles = makeStyles((theme) => ({
  containerTable: {
    display: "flex",
    gap: "5rem",
    width: "27rem",
    fontSize: "0.875rem",
    [theme.breakpoints.down(1280)]: {
      gap: "2rem",
    },
    [theme.breakpoints.down(1200)]: {
      width: "auto",
      justifyContent: "center",
    },
  },
  referenceClick: {
    width: "38rem",
    [theme.breakpoints.down(1200)]: {
      display: "none",
    },
    "& >div": {
      display: "flex",
      fontWeight: 300,
      boxShadow: "4.4px 4.4px 22.2px 2.2px rgba(0,0,0,0.2)",
      borderRadius: 30,
      padding: "0.5rem 1rem",
      gap: "1rem",
      color: theme.palette.text.secondary,
      alignItems: "center",
      width: "30rem",
      justifyContent: "center",
      margin: "0 auto",
    },
  },
}));
