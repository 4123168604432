import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: "38rem",
    position: "relative",
    fontFamily: "Montserrat",
    "& .iconArrow": {
      width: "1rem",
      color: theme.palette.text.secondary,
    },
    "& >.subTable": {
      display: "flex",
      maxWidth: "40rem",
      gap: "5rem",
      [theme.breakpoints.down(1200)]: {
        display: "block",
      },
    },
    "& .lineRight": {
      position: "absolute",
      right: "-2.2rem",
      top: "2rem",
      [theme.breakpoints.down(1200)]: {
        display: "none",
      },
    },
    "& .lineLeft": {
      position: "absolute",
      left: "-2.2rem",
      top: "2rem",
      transform: "scaleX(-1)",
      [theme.breakpoints.down(1200)]: {
        display: "none",
      },
    },
    "& .subTableLineLeft": {
      position: "absolute",
      left: "-2.2rem",
      top: "2rem",
      [theme.breakpoints.down(1200)]: {
        display: "none",
      },
    },
    "& .subTableLineRight": {
      position: "absolute",
      right: "16.2rem",
      top: "2rem",
      [theme.breakpoints.down(1200)]: {
        display: "none",
      },
    },
  },
  buttonPurple: {
    position: "relative",
    display: "block",
    padding: "1rem",
    color: "white",
    background: "#8352fd",
    borderRadius: 20,
    width: "16.5rem",
    textAlign: "center",
    marginBottom: "2rem",
    [theme.breakpoints.down(1200)]: {
      width: "20rem",
      margin: "0 auto",
      color: "#8352fd",
      background: "none",
      textAlign: "start",
      padding: "1rem 0",
      fontWeight: "bold",
    },
  },
  buttonPurplePrincipal: {
    fontSize: "1.25rem",
    width: "23.94rem",
    margin: "0 auto",
    marginBottom: "2rem",
    marginTop: "2rem",
    [theme.breakpoints.down(1200)]: {
      width: "20rem",
      margin: "0 auto",
      fontSize: "1rem",
      marginBottom: "1rem",
      color: "white",
      background: "#8352fd",
      textAlign: "center",
    },
  },
  inlineButton: {
    padding: "0 1rem",
    boxShadow: "4.4px 4.4px 22.2px 2.2px rgba(0,0,0,0.2)",
    borderRadius: 15,
    display: "flex",
    alignItems: "center",
    width: "12.5rem",
    height: "3.125rem",
    color: theme.palette.text.primary,
    marginBottom: "1.44rem",
    fontWeight: "bold",
    cursor: "pointer",
    [theme.breakpoints.down(1200)]: {
      width: "16.5rem",
      justifyContent: "space-between",
    },
  },
  button: {
    marginTop: "3rem",
    marginBottom: "5rem",
    [theme.breakpoints.down(1200)]: {
      display: "flex",
      justifyContent: "center",
      margin: "2rem auto",
    },
  },
  selectButton: {
    border: "1px solid #8352fd",
    "& .iconArrow": {
      transform: "rotate(90deg)",
      transition: "0.3s",
      width: "1rem",
    },
  },
  selectIcon: {
    background: "#8352fd",
    "& img": {
      filter: "invert(1)",
    },
  },
  inlineIcon: {
    width: "3rem",
    padding: 0,
    "& img": {
      width: "1.7rem",
      margin: "0 auto",
    },
  },
  containerButton: {
    display: "flex",
    gap: "1rem",
  },
  descriptionMobile: {
    lineHeight: 2.14,
    color: theme.palette.text.primary,
    padding: "0rem 0 2rem",
    width: "20rem",
    margin: "0 auto",
    "& span": {
      fontWeight: 300,
    },
    "& strong": {
      fontWeight: "bold",
    },
  },
}));
