import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./table.style";
import { ButtonPrimary } from "@components/Reusables";
import {
  ACTIONS_ICON,
  AFP_ICON,
  APV_ICON,
  BILL_ICON,
  DAP_ICON,
  ETF_ICON,
  FOUND_INVESTMENT_ICON,
  FOUND_MUTUAL,
  SURE_ICON,
} from "@images";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

interface TableMP1Props {
  setButtonSelect: (num: number) => void;
  buttonSelect: number;
  handleClick: () => void;
}

const TableMB1 = ({
  setButtonSelect,
  buttonSelect,
  handleClick,
}: TableMP1Props) => {
  const isMobile = useMediaQuery("(max-width: 1200px)");
  const classes = useStyles();

  return (
    <div>
      <section className={classes.table}>
        <div
          className={`${classes.buttonPurple} ${classes.buttonPurplePrincipal}`}
        >
          Métodos de Ahorro e Inversión{" "}
          <img
            className="lineRight"
            src="https://rokinapp-images.s3.amazonaws.com/M.B.1/Vector+1+(2).svg"
          />{" "}
          <img
            className="lineLeft"
            src="https://rokinapp-images.s3.amazonaws.com/M.B.1/Vector+1+(2).svg"
          />
        </div>
        <div className="subTable">
          <div>
            <span className={classes.buttonPurple}>
              {isMobile && "1."} Retiro en cualquier momento
              <img
                className="subTableLineLeft"
                src="https://rokinapp-images.s3.amazonaws.com/M.B.1/Group+2.svg"
              />
            </span>
            <div>
              {buttonList1.map((item) => (
                <>
                  <div key={item.id} className={classes.containerButton}>
                    <span
                      className={`${classes.inlineButton} ${
                        buttonSelect === item.id && classes.selectIcon
                      } ${classes.inlineIcon}`}
                      onClick={() => setButtonSelect(item.id)}
                    >
                      <img src={item.icon} />
                    </span>
                    <span
                      className={`${classes.inlineButton} ${
                        buttonSelect === item.id && classes.selectButton
                      }`}
                      onClick={() => setButtonSelect(item.id)}
                    >
                      {item.title}
                      {isMobile && (
                        <ArrowForwardIosIcon className={"iconArrow"} />
                      )}
                    </span>
                  </div>
                  {isMobile && buttonSelect === item.id && (
                    <div className={classes.descriptionMobile}>
                      {item.description}
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
          <div>
            <span className={classes.buttonPurple}>
              {isMobile && "2."} Retiro en tu jubilación
              <img
                className="subTableLineRight"
                src="https://rokinapp-images.s3.amazonaws.com/M.B.1/Group+1.svg"
              />
            </span>
            <div>
              {buttonList2.map((item) => (
                <>
                  <div key={item.id} className={classes.containerButton}>
                    <span
                      className={`${classes.inlineButton} ${
                        buttonSelect === item.id && classes.selectIcon
                      } ${classes.inlineIcon}`}
                      onClick={() => setButtonSelect(item.id)}
                    >
                      <img src={item.icon} />
                    </span>
                    <span
                      className={`${classes.inlineButton} ${
                        buttonSelect === item.id && classes.selectButton
                      }`}
                      onClick={() => setButtonSelect(item.id)}
                    >
                      {item.title}{" "}
                      {isMobile && (
                        <ArrowForwardIosIcon className={"iconArrow"} />
                      )}
                    </span>
                  </div>
                  {isMobile && buttonSelect === item.id && (
                    <div className={classes.descriptionMobile}>
                      {item.description}
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </section>{" "}
      <ButtonPrimary
        className={classes.button}
        text="Terminar misión"
        secondaryVersion
        onClick={() => {
          handleClick();
        }}
        noDegraded
      />
    </div>
  );
};

export default TableMB1;

export const buttonList1 = [
  {
    id: 1,
    title: " Depósito a Plazo",
    icon: DAP_ICON,
    description: (
      <span>
        Monto que se le entrega a una institución financiera por un
        <strong> plazo determinado</strong>, posiblemente renovable, para que lo
        hagan
        <strong> crecer en un monto prometido seguro.</strong>
      </span>
    ),
  },
  {
    id: 2,
    title: "Fondos mutuos",
    icon: FOUND_MUTUAL,
    description: (
      <span>
        Los fondos mutuos son
        <strong> fondos de inversión públicos, </strong>
        que permiten el
        <strong> rescate total </strong>
        de sus cuotas (retiro de tus fondos), de manera permanente,
        <strong> en 10 o menos días.</strong>
      </span>
    ),
  },
  {
    id: 3,
    title: "Acciones",
    icon: ACTIONS_ICON,
    description: (
      <span>
        Son un tipo de inversión sobre la
        <strong> propiedad de una empresa. </strong>
        Así, si tienes acciones de una empresa, eres
        <strong> dueño de un porcentaje </strong>
        de la empresa (y si la empresa reparte utilidades, te corresponde a ti
        también recibir un porcentaje).
      </span>
    ),
  },
  {
    id: 4,
    title: "Fondos de Inversión",
    icon: FOUND_INVESTMENT_ICON,
    description: (
      <span>
        Es una cantidad de plata aportada por
        <strong> varias personas o empresas</strong>, para que sea invertido de
        acuerdo a un
        <strong> objetivo específico</strong>. Son administrados por
        <strong> sociedades especiales </strong>
        destinadas solamente a eso. Los fondos pueden ser
        <strong> activos o pasivos.</strong>
      </span>
    ),
  },
  {
    id: 5,
    title: "ETF (Fondo cotizado)",
    icon: ETF_ICON,
    description: (
      <span>
        Los
        <strong> Exchange Traded Funds </strong>
        no son una categoría propia de fondos en Chile, sino que es una
        denominación para fondos que cumplen con ciertas características: son
        fondos de inversión
        <strong> pasivos</strong>, por lo que son de
        <strong> bajo costo</strong>, y que además son
        <strong> transados en bolsa.</strong>
      </span>
    ),
  },
  {
    id: 6,
    title: "Cuenta 2 AFP",
    icon: BILL_ICON,
    description: (
      <span>
        La cuenta 2 es una
        <strong> alternativa de inversión </strong>
        en los fondos administrados por una AFP, de manera
        <strong> voluntaria </strong>y<strong> pudiendo retirar </strong>
        la inversión libremente.
      </span>
    ),
  },
];

export const buttonList2 = [
  {
    id: 7,
    title: "AFP",
    icon: AFP_ICON,
    description: (
      <span>
        Cotización equivalente al
        <strong> 10% del sueldo bruto </strong>
        de los empleados con contrato, que se destina al
        <strong> ahorro para la jubilación</strong>. Además del 10% se debe
        agregar la
        <strong> comisión </strong>
        que cobra la AFP, la que depende de cada AFP de manera individual.
      </span>
    ),
  },
  {
    id: 8,
    title: "APV",
    icon: APV_ICON,
    description: (
      <span>
        Es una
        <strong> inversión voluntaria, adicional y complementaria</strong>, a la
        cotización obligatoria en una AFP, que tiene por objetivo
        <strong> aumentar </strong>
        tu futura pensión o<strong> compensar </strong>
        lagunas previsionales. Lo pueden hacer trabajadores dependientes e
        independientes. Tiene importantes
        <strong> beneficios tributarios</strong>, que dependen del régimen de
        APV elegido (A o B).
      </span>
    ),
  },
  {
    id: 9,
    title: "Seguros con ahorro",
    icon: SURE_ICON,
    description: (
      <span>
        Es un seguro, generalmente uno de
        <strong> vida</strong>, donde se
        <strong> destina una parte </strong>
        de lo que pagas al seguro mismo y otra a una
        <strong> inversión a tu nombre.</strong>
      </span>
    ),
  },
];
