import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "23.9rem",
    minHeight: "18.5rem",
    boxShadow: "4.4px 4.4px 22.2px 2.2px rgba(0,0,0,0.2)",
    borderRadius: 30,
    padding: "2.25rem 1.5rem 2.25rem",
    fontFamily: "Montserrat",
    marginTop: "4rem",
    [theme.breakpoints.down(1280)]: {
      width: "20.9rem",
    },
  },
  icon: {
    width: "3rem",
    margin: "0.5rem auto 1rem",
    filter: "invert(0.2) sepia(2) hue-rotate(209deg) saturate(443%)",
    "& svg": {
      color: "#8352fd",
    },
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    textAlign: "center",
    margin: "1rem 0",
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: "0.85rem",
    padding: "0.5rem 1rem",
    lineHeight: 2.14,
    color: theme.palette.text.primary,
  },
}));
