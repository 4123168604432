import React from "react";
import { useStyles } from "./descriptionTable.style";
import { buttonList1, buttonList2 } from "../table/table";

interface TableMP1Props {
  buttonSelect: number;
}

const DescriptionTable = ({ buttonSelect }: TableMP1Props) => {
  const classes = useStyles();

  let icon;
  let title;
  let description;

  buttonList1.forEach((item) => {
    if (item.id === buttonSelect) {
      icon = item.icon;
      title = item.title;
      description = item.description;
    }
  });
  buttonList2.forEach((item) => {
    if (item.id === buttonSelect) {
      icon = item.icon;
      title = item.title;
      description = item.description;
    }
  });
  return (
    <>
      <section className={classes.container}>
        <img className={classes.icon} src={icon} />
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{description}</div>
      </section>{" "}
    </>
  );
};

export default DescriptionTable;
